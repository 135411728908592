import React, { lazy } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
const PublicRoutes = lazy(() => import("./PublicRoutes"));

const AppRouter = () => <Router> <Route component={PublicRoutes} /></Router >

export default AppRouter;



